import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

//News Detail
import DetailNews from "./pages/news/NewsDetail";
import PencarianView from "./pages/news/Pencarian";

import logoTikom from "./assets/disdikjabar.png";
import logoMegaphone from "./assets/megaphone_art2.png";



// Prestasi

const LazyContact = lazy(() => import("./pages/about_us/Contact"));
const LazyFAQ = lazy(() => import("./pages/faq/faq"));


// Layanan Publik
const LazyLayananPublikSelengkapnya = lazy(() => import("./pages/layanan-publik/LayananSelengkapnya"));
const LazyLayananSiswa = lazy(() => import("./pages/layanan-publik/diba-layanan-siswa"));
const LazyLayananKepegawaian = lazy(() => import("./pages/layanan-publik/diba-layanan-kepegawaian"));

// Layanan Internal
const LazyLayananInternalSelengkapnya = lazy(() => import("./pages/layanan-internal/diba-laper"));

// PPID
const LazyHomeDaftarInformasi = lazy(() => import("./pages/ppid/HomeDaftarInformasi"));
const LazyPermohonanInformasiPublik = lazy(() => import("./pages/ppid/formulir_informasi_publik/LayananPublik"));
const LazyPermohonanInformasiPublikOnline = lazy(() => import("./pages/ppid/formulir_informasi_publik/FormulirOnline"));
const LazyPermohonanInformasiPublikOffline = lazy(() => import("./pages/ppid/formulir_informasi_publik/FormulirOffline"));
const LazyPermohonanTataCaraInformasiPublik = lazy(() => import("./pages/ppid/formulir_informasi_publik/TataCaraLayanan"));
const LazyPermohonanTataCaraPengaduanInformasi = lazy(() => import("./pages/ppid/pengaduan_publik/TataCaraPengaduan"));
const LazyPengaduanInformasiPublikOffline = lazy(() => import("./pages/ppid/pengaduan_publik/FormulirPengaduanOffline"));
const LazyPengaduanInformasiPublikOnline = lazy(() => import("./pages/ppid/pengaduan_publik/FormulirPengaduanOnline"));
const LazyInformasiSertaMerta = lazy(() => import("./pages/ppid/daftar_informasi_publik/InformasiSertaMerta"));
const LazyProfilPpid = lazy(() => import("./pages/ppid/ProfilPpid"));
const LazyInformasiPublikDetail = lazy(() => import("./pages/ppid/daftar_informasi_publik/DetailInformasiPublik"));
const LazyPermohonanInformasi = lazy(() => import("./pages/ppid/pengaduan_publik/TataCaraPermohonan"));
const LazyPermohonanPengaduan = lazy(() => import("./pages/ppid/pengaduan_publik/TataCaraPengaduan"));

//NotFound
const LazyNotFoundPage = lazy(() => import("./pages/template/NotFound"));
const LazyUnderConstructPage = lazy(() =>
  import("./pages/template/UnderConstruct")
);
const LazyLayananPpid = lazy(() =>
  import("./pages/ppid/LayananPpid")
);

//Kalender Pendidikan
const LazyKalenderPendidikan = lazy(() => import("./pages/layanan-publik/KalendarPendidikan"));
const LazyLapIjazah = lazy(() => import("./pages/layanan-publik/diba-lapijazah"));

// Profile
const LazyProfil = lazy(() => import("./pages/profile/Profil"));
const LazyVisiMisi = lazy(() => import("./pages/profile/VisiMisi"));
// const LazyStruktur = lazy(() => import("./pages/profile/Struktur"));
const LazySejarah = lazy(() => import("./pages/profile/Sejarah"));
const LazyProfilTikomdik = lazy(() => import("./pages/profile/ProfilTikomdik"));
const LazyLambangDisdik = lazy(() => import("./pages/profile/Lambang"));
const LazyProfilePsma = lazy(() => import("./pages/profile/ProfilPsma"));
const LazyProfilePsmk = lazy(() => import("./pages/profile/ProfilPsmk"));
const LazyProfileSekretariat = lazy(() => import("./pages/profile/ProfilSekretariat"));
const LazyProfilePklk = lazy(() => import("./pages/profile/ProfilPklk"));
const LazyProfileGtk = lazy(() => import("./pages/profile/ProfilGtk"));
const LazyProfileKcd = lazy(() => import("./pages/profile/ProfilKcd"));

// PPDB
const LazyPpdb = lazy(() => import("./pages/ppdb/Ppdb"));
const LazyAduanPPDB = lazy(() => import("./pages/ppdb/UploadAduan"));

//News Main
const LazyNewsPage = lazy(() => import("./pages/news/NewsPage"));

//Sapa Disdik
const LazyAgenda = lazy(() => import("./pages/agenda/Agenda"));
const LazySapaDisdikPiradio = lazy(() => import("./pages/sapa_disdik/Piradio"));
// const LazySapaDisdikPiradio = lazy(() => import("./pages/sapa_disdik/Piradio"));
const LazySapaDisdikPodcast = lazy(() =>
  import("./pages/sapa_disdik/NavSapaDisdik")
);

const LazyHomePage = lazy(() => import("./pages/home/HomePage"));





class Routes extends React.Component {
  state = {
    modal14: true,
  };
  linkTo() {
    this.props.history.replace("/");
  }
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };
  render() {
    const ComponentUnder = () => (
      <>
        <MDBModal
          isOpen={this.state.modal14}
          toggle={this.toggle(14)}
          centered
          overflowScroll={false}
        >
          <MDBModalHeader>
            <div
              className="d-flex flex-row align-items-center"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <div>
                <img src={logoTikom} width={80} alt="logo" />
              </div>
              <div className="ml-2"> Dinas Pendidikan Jawa Barat </div>
            </div>
          </MDBModalHeader>
          <MDBModalBody className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
              <div>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </div>
              <div className="ml-2"> Pemberitahuan </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-12 col-lg-3 ">
                <img src={logoMegaphone} width={90} alt="" />
              </div>
              <div className="col-sm-12 col-lg-9">
                <div>
                  <h5>
                    <b> Konten website sedang dalam pembaharuan </b>
                  </h5>
                  <p>
                    Mohon kunjungi secara berkala website ini untuk mendapatkan
                    informasi terbaru
                  </p>
                </div>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      </>
    );
    return (
      <Suspense fallback={<div> Loading... </div>}>

        <Switch>
          <CompatRoute exact path="/" component={LazyHomePage} />
          {/* //PPDB */}
          <CompatRoute exact path="/ppdb" component={LazyPpdb} />
          <CompatRoute exact path="/aduan-ppdb" component={LazyAduanPPDB} />
          {/* //Programs //Informasi */}
          <CompatRoute path="/profile/profil" component={LazyProfil} />
          <CompatRoute path="/profile/sejarah" component={LazySejarah} />
          <CompatRoute path="/profile/visimisi" component={LazyVisiMisi} />
          {/* <CompatRoute path="/profile/struktur" component={LazyStruktur} /> */}
          {/* //profile */}
          <CompatRoute path="/profile/profil-tikomdik" component={LazyProfilTikomdik} />
          <CompatRoute path="/profile/profil-psma" component={LazyProfilePsma} />
          <CompatRoute path="/profile/profil-psmk" component={LazyProfilePsmk} />
          <CompatRoute path="/profile/profil-sekretariat" component={LazyProfileSekretariat} />
          <CompatRoute path="/profile/profil-pklk" component={LazyProfilePklk} />
          <CompatRoute path="/profile/profil-gtk" component={LazyProfileGtk} />
          <CompatRoute path="/profile/profil-kcd" component={LazyProfileKcd} />
          <CompatRoute path="/profile/brand-identity" component={LazyLambangDisdik} />
          {/* //Sapa Disdik */}
          <CompatRoute path="/sapa-disdik/piradio" component={LazySapaDisdikPiradio} />
          {/* <CompatRoute path="/piradio" component={LazySapaDisdikPiradio} /> */}
          <CompatRoute path="/sapa-disdik/konten-digital" component={LazySapaDisdikPodcast} />
          {/* //Agenda */}
          <CompatRoute path="/sapa-disdik/agenda" component={LazyAgenda} />

          {/* //Kalender Pendidikan */}
          <CompatRoute path="/pages/layanan-publik/KalendarPendidikan" component={LazyKalenderPendidikan} />

          {/* //PPID */}
          <CompatRoute exact path="/ppid/daftar-informasi" component={LazyHomeDaftarInformasi} />
          <CompatRoute exact path="/ppid/informasi-publik" component={LazyPermohonanInformasiPublik} />
          <CompatRoute path="/ppid/formulir/informasi-publik/online" component={LazyPermohonanInformasiPublikOnline} />
          <CompatRoute path="/ppid/formulir/informasi-publik/offline" component={LazyPermohonanInformasiPublikOffline} />
          <CompatRoute path="/ppid/infografis/informasi-publik/tatacaralayanan" component={LazyPermohonanTataCaraInformasiPublik} />
          <CompatRoute path="/ppid/infografis/pengaduan-publik/tatacarapengaduan" component={LazyPermohonanTataCaraPengaduanInformasi} />
          <CompatRoute path="/ppid/formulir/pengaduan-publik/pengaduan-offline" component={LazyPengaduanInformasiPublikOffline} />
          <CompatRoute path="/ppid/formulir/pengaduan-publik/pengaduan-online" component={LazyPengaduanInformasiPublikOnline} />
          <CompatRoute path="/ppid/profil-ppid" component={LazyProfilPpid} />
          <CompatRoute path="/ppid/detail/:link_ppid" component={LazyInformasiPublikDetail} />
          <CompatRoute path="/ppid/daftar-informasi-publik/informas-serta-merta" component={LazyInformasiSertaMerta} />
          <CompatRoute path="/pages/ppid/layanan-ppid" component={LazyLayananPpid} />
          <CompatRoute path="/ppid/pengaduan-publik/permohonan-informasi" component={LazyPermohonanInformasi} />
          <CompatRoute path="/ppid/pengaduan-publik/permohonan-pengaduan" component={LazyPermohonanPengaduan} />
          {/* /* //Layanan Publik  */}
          <CompatRoute path="/pages/layanan-publik/LayananSelengkapnya" component={LazyLayananPublikSelengkapnya} />
          <CompatRoute path="/pages/layanan-publik/diba-layanan-siswa" component={LazyLayananSiswa} />
          <CompatRoute path="/pages/layanan-publik/diba-layanan-kepegawaian" component={LazyLayananKepegawaian} />
          <CompatRoute path="/layanan-publik/lapor-ijazah" component={LazyLapIjazah} />

          {/* /* //Layanan Internal */}
          <CompatRoute exact path="/pages/layanan-internal/diba-laper" component={LazyLayananInternalSelengkapnya} />
          //FAQc */
          <CompatRoute exact path="/frequently-asked-questions" component={LazyFAQ} />

          {/* //News //OLD ROUTE */}
          <CompatRoute exact path="/news" component={LazyNewsPage} />
          <CompatRoute exact path="/news/:base/detail/:id" component={DetailNews} />

          {/* //NEW ROUTE */}
          <CompatRoute exact path="/informasi/berita" component={LazyNewsPage} />
          <CompatRoute exact path="/informasi/berita/:base/detail/:id" component={DetailNews} />
          <CompatRoute exact path="/berita" component={LazyNewsPage} />
          <CompatRoute exact path="/berita/:id" component={DetailNews} />
          <CompatRoute exact path="/pencarian" component={PencarianView} />
          {/* //contact */}
          <CompatRoute exact path="/about_us" component={LazyContact} />

          {/* //Kegiatan //Tikomdik Under Construct */}
          <CompatRoute exact path="/halaman/tahap_pengembangan" component={LazyUnderConstructPage} />
          <CompatRoute component={() => <Redirect to="/halaman/tahap_pengembangan" />} path="/tahap_pengembangan" />
          {/* //Under Construct */}
          <CompatRoute exact path="/404" component={LazyNotFoundPage} />
          <CompatRoute exact path="/halaman/tahap_pengembangan" component={LazyUnderConstructPage} />
          <CompatRoute component={() => <Redirect to="/halaman/tahap_pengembangan" />} path="/tahap_pengembangan" />
          <CompatRoute path="" component={LazyNotFoundPage} />
          <CompatRoute path="*" component={LazyNotFoundPage} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
